<script setup>
import {inject, defineProps} from "vue";

// Inject the handleNotice function provided by the parent
const showToastMessage = inject("showToastMessage");

if (!showToastMessage) {
  console.error("showToastMessage is not provided");
}

const props = defineProps({
  showDashboardTab: Boolean
});
</script>

<template>
  <div v-if="props.showDashboardTab">
    <h1>Dashboard Page</h1>
    <button @click="showToastMessage && showToastMessage('info', 'Information', 'This is an info message')">Show Info Toast</button>
  </div>
</template>

<style scoped>

</style>