import { definePreset } from '@primevue/themes';
import Lara from '@primevue/themes/lara';

export const StylePreset = definePreset(Lara, {
    semantic: {
        primary: {
            50: '{blue.50}',
            100: '{blue.100}',
            200: '{blue.200}',
            300: '{blue.300}',
            400: '{blue.400}',
            500: '{blue.500}',
            600: '{blue.600}',
            700: '{blue.700}',
            800: '{blue.800}',
            900: '{blue.900}',
            950: '#00205B'
        },
        colorScheme: {
            light: {
                primary: {
                    color: '#14377D',
                    inverseColor: '#ffffff',
                    hoverColor: '#052667',
                    activeColor: '#052667',
                    textColor: '#ffffff'
                },
                highlight: {
                    background: '#EFF6FF',
                    focusBackground: 'rgba(#14377D, .1)',
                    color: '#ffffff',
                    textColor: '#052667',
                    borderColor: '#052667'
                    // focusColor: '#ffffff'
                }
            }
        }
    },
    components: {
        button: {
            colorScheme: {
                light: {
                    outlined: {
                        primary: {
                            borderColor: '#052667'
                        }
                    }
                }
            }
        },
        select: {
            option: {
                focusColor: '#052667',
                selectedColor: '#052667'
            }
        },
        paginator: {
            navButton: {
                selectedColor: '#052667'
            }
        },
        menu: {
            item: {
                focusBackground: 'rgba(255,255,255,0.1)',
                color: 'rgba(255,255,255,1)',
                focusColor: 'rgba(255,255,255,1)',
            },
        }
    }
});
