<script>
export default {
  name: "ResetPasswordWithCodeCard",
  props: {
    email: {
      type: String,
      required: true,
    },
    resetPasswordStatus: {
      type: String,
      required: true,
    },
    sendResetCodeStatus: {
      type: String,
      required: true,
    },
  },
  computed: {
    isCodeComplete() {
      return this.resetPasswordCode.length === 6;
    },
    isPasswordValid() {
      return this.hasLowerCase && this.hasUpperCase && this.hasNumeric && this.hasSpecialChar && this.isLongEnough;
    },
    maskedEmail() {
      console.log(this.email);
      const [username, domain] = this.email.split('@');
      return `${username[0]}****${username.slice(-1)}@${domain}`;
    },
    keepLoading() {
      return this.resetPasswordStatus !== 'error';
    },
  },
  watch: {
    keepLoading(newVal) {
      if (!newVal) {
        this.loading = false;
      }
    },
    sendResetCodeStatus(newStatus) {
      console.log('newStatus', newStatus);
      this.resendClickable = newStatus !== 'pending';
    },
  },
  data() {
    return {
      resetPasswordCode: '',
      resendClickable: true,
      loading: false,
      newPassword: '',
      confirmPassword: '',
      hasLowerCase: false,
      hasUpperCase: false,
      hasNumeric: false,
      hasSpecialChar: false,
      isLongEnough: false,
    };
  },
  methods: {
    cancelReset() {
      this.$emit('cancel-reset');
    },
    validatePassword() {
      const password = this.newPassword;

      this.isLongEnough = password.length >= 8;
      this.hasUpperCase = /[A-Z]/.test(password);
      this.hasLowerCase = /[a-z]/.test(password);
      this.hasSpecialChar = /[@$!%*#?&]/.test(password);
      this.hasNumeric = /\d/.test(password);
    },
    setNewPassword() {
      console.log('set new password in reset password with code card');
      this.loading = true;
      if (this.newPassword !== this.confirmPassword || !this.newPassword || !this.confirmPassword) {
        this.$emit('show-toast-message', 'error', 'Error', 'Passwords do not match');
        this.loading = false;
        return;
      }

      this.$emit('set-new-password', this.resetPasswordCode, this.newPassword);
    },
    resendCode() {
      this.$emit('resend-code', this.email);
    },
  },
}
</script>

<template>
  <h3 class="text-2xl font-medium">Reset Password</h3>
  <p class="px-8 pt-6">Enter the verification code send to </p>
  <p class="px-8 pb-6">{{ maskedEmail }} </p>
  <div class="px-6">
    <div class="flex space-x-2 mb-6">
      <InputOtp v-model="resetPasswordCode" :length="6" style="gap: 8px" class="w-full">
        <template #default="{ attrs, events }">
          <input type="text" v-bind="attrs"
                 v-on="events"
                 class="h-12 w-12 text-center text-xl border border-gray-300 rounded"
                 autocomplete="one-time-code"/>
        </template>
      </InputOtp>
    </div>
  </div>

  <div class="card flex flex-wrap justify-center px-6 gap-6 mb-8">
    <div class="icon-field-row w-full">
      <IconField>
        <InputIcon class="pi pi-lock z-10"/>
        <Password class="w-full [&_input]:pl-10 [&_input]:w-full" v-model="newPassword" placeholder="Password"
                  :feedback="true" @input="validatePassword"
                  strong-regex="^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[@$!%*#?&])(?=.{8,})" toggleMask
                  autocomplete="off">
          <template #header>
            <div class="font-semibold text-xm mb-4">Pick a password</div>
          </template>
          <template #footer>
            <Divider/>
            <ul class="pl-2 ml-2 my-0 leading-normal">
              <li :class="{ 'text-green-500': hasLowerCase, 'text-red-500': !hasLowerCase }">At least one lowercase</li>
              <li :class="{ 'text-green-500': hasUpperCase, 'text-red-500': !hasUpperCase }">At least one uppercase</li>
              <li :class="{ 'text-green-500': hasNumeric, 'text-red-500': !hasNumeric }">At least one numeric</li>
              <li :class="{ 'text-green-500': hasSpecialChar, 'text-red-500': !hasSpecialChar }">At least one special
                character
              </li>
              <li :class="{ 'text-green-500': isLongEnough, 'text-red-500': !isLongEnough }">Minimum 8 characters</li>
            </ul>
          </template>
        </Password>
      </IconField>
    </div>

    <div class="icon-field-row w-full">
      <IconField>
        <InputIcon class="pi pi-lock z-10"/>
        <Password class="w-full [&_input]:pl-10 [&_input]:w-full" v-model="confirmPassword"
                  placeholder="Confirm Password"
                  :feedback="false" toggleMask>
        </Password>
      </IconField>
    </div>
  </div>


  <div class="mt-4 mb-8">
    <span class="text-blue-900 mr-2">Didn't receive the code?</span>
    <a @click.prevent="resendCode"
       class="font-bold text-blue-900 cursor-pointer hover:underline"
       :class="{ 'cursor-not-allowed text-gray-400': (!resendClickable&&loading&&keepLoading) }"
       :style="{ pointerEvents: resendClickable&&!(loading&&keepLoading) ? 'auto' : 'none', cursor: resendClickable&&!(loading&&keepLoading) ? 'pointer' : 'none' }"
    >
      Resend code
    </a>
  </div>
  <div class="grid gap-6 px-6 grid-cols-2 mt-8 w-full">
    <Button label="Cancel" @click="cancelReset" outlined/>
    <Button label="Reset Password" @click="setNewPassword" class="px-0.5"
            :disabled="!isCodeComplete || (loading&&keepLoading) || !isPasswordValid" :loading="loading&&keepLoading"/>
  </div>
</template>

<style scoped>

</style>