import axios from "axios";

const TabService = {
    async listUserTabPermissions(config, store, router) {
        const backendUrl = process.env.VUE_APP_API_BASE_URL;
        try {
            console.log('Getting tabs');
            const response = await axios.get(backendUrl + '/api/permissions/tabs', config);
            this.tabs = response.data;
        } catch (error) {
            console.error('Error loading permissions!', error);
            //if status is 401, redirect to login
            if (error.response.status === 401) {
                store.commit('setUserToken', '');
                store.commit('setUserName', '');
                store.commit('setShowTokenExpiringMessage', "You don't have permissions to access the dashboard.");
                router.push('/login');
            }
        }
        return this.tabs;
    },
    async getFirstAvailableTabUrl(config, store, router) {
        if (!this.tabs) {
            await this.listUserTabPermissions(config, store, router);
        }

        if (!this.tabs) {
            return '/';
        }

        if (this.tabs.includes('dashboard')) {
            return '/dashboard';
        } else if (this.tabs.includes('schools')) {
            return '/schools';
        } else if (this.tabs.includes('users')) {
            return '/users';
        } else if (this.tabs.includes('upload')) {
            return '/upload';
        } else if (this.tabs.includes('api')) {
            return '/eligibility-check';
        } else if (this.tabs.includes('emails')) {
            return '/emails';
        } else if (this.tabs.includes('docs')) {
            return '/docs';
        }
        return '/';
    },
}


export default TabService;
