<template>
<!--  <basic-layout>-->
<!--  </basic-layout>-->
  <router-view></router-view>
</template>

<script>
// import BasicLayout from "@/layouts/BasicLayout.vue";

export default {
  name: 'App',
  // components: {
  //   BasicLayout,
  // },
};
</script>

<style scoped>
</style>
