<template>
  <div id="mfa-card-wrapper" class="mx-auto">
    <h3 class="text-2xl font-medium">Enter verification code</h3>
    <p class="my-4 px-8">Enter the verification code sent to {{ maskedEmail }}</p>
    <div class="px-6">
      <div class="flex space-x-2 mb-6">
        <InputOtp v-model="otpCode" :length="6" style="gap: 8px" class="w-full">
          <template #default="{ attrs, events }">
            <input type="text" v-bind="attrs" v-on="events"
                   class="h-12 w-12 text-center text-xl border border-gray-300 rounded" autocomplete="one-time-code"/>
          </template>
        </InputOtp>
      </div>

      <div class="mt-4">
        <span class="text-blue-900 mr-2">Didn't receive a code?</span>
        <a @click.prevent="resendCode"
           class="font-bold text-blue-900 cursor-pointer hover:underline"
           :class="{ 'cursor-not-allowed text-gray-400': (!resendClickable&&loading&&keepLoading) }"
           :style="{ pointerEvents: resendClickable&&!(loading&&keepLoading) ? 'auto' : 'none', cursor: resendClickable&&!(loading&&keepLoading) ? 'pointer' : 'none' }">
          Resend
        </a>
      </div>
    </div>
    <div class="grid gap-6 px-6 grid-cols-2 mt-8">
      <Button label="Cancel" @click="cancelMfa" outlined/>
      <Button label="Login" @click="verifyCode" :disabled="!isMfaCodeComplete || (loading&&keepLoading)"
              :loading="loading&&keepLoading"/>
    </div>
  </div>
</template>

<script>
export default {
  name: 'MfaCard',
  props: {
    email: {
      type: String,
      required: true,
    },
    mfaStatus: {
      type: String,
      required: true,
    },
  },
  data() {
    return {
      otpCode: '',
      loading: false,
      resendClickable: true,
    };
  },
  watch: {
    mfaStatus(newStatus) {
      console.log('newStatus', newStatus);
      this.resendClickable = newStatus !== 'pending';
      if (newStatus === 'error') {
        this.loading = false;
      }
    },
  },
  methods: {
    resendCode() {
      if (this.loading) return;
      this.$emit('resend-code');
    },
    cancelMfa() {
      this.$emit('cancel-mfa');
    },
    verifyCode() {
      this.loading = true;
      this.$emit('verify-code', this.email, this.otpCode);
    },
  },
  computed: {
    isMfaCodeComplete() {
      return this.otpCode.length === 6;
    },
    maskedEmail() {
      const [username, domain] = this.email.split('@');
      return `${username[0]}****${username.slice(-1)}@${domain}`;
    },
    keepLoading() {
      //if loginStatus is error, return false
      return this.mfaStatus !== 'error';
    },
  },
};
</script>

<style scoped>

</style>