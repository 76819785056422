<template>
  <main>
    <div class="unauthenticatedContainer grid min-h-screen justify-center items-center">
      <slot></slot>
    </div>
  </main>
</template>

<script>
export default {
  name: 'UnauthenticatedLayout',
};
</script>

<style scoped>

</style>