<template>
  <main>
    <UnauthenticatedLayout>
      <Toast position="top-right" group="tr" />
      <div id="login-card-container" class="m-0 mx-auto max-w-[420px] grid justify-center items-center text-center">
        <Card id="login-card" class="bg-white bg-opacity-95 pb-8">
          <template #content>
            <img id="logo" class="mx-auto w-4/5" alt="user header" src="/images/GM_Digital_Doctor_Logo_RGB_150dpi.png"/>
            <LoginCard ref="loginCard" v-if="step === steps.login" :loginStatus="this.loginStatus" @trigger-login="login" @forget-password="forgetPassword" />
            <MfaCard v-else-if="step === steps.mfaVerification" :email="this.email" :mfaStatus="mfaStatus" @resend-code="resendCode" @cancel-mfa="cancelMfa" @verify-code="verifyMfaCode" />
            <SetPasswordCard v-else-if="step === steps.setPassword" :setPasswordStatus="this.setPasswordStatus" @cancel-set-password="cancelSetPassword" @login-with-temp-password="loginWithTempPassword" @show-toast-message="showToastMessage" />
            <ForgetPasswordCard v-else-if="step === steps.forgetPassword" :sendResetCodeStatus="this.sendResetCodeStatus" @request-reset-password-code="requestResetPasswordCode" @cancel-forget-password="cancelForgetPassword"/>
            <ResetPasswordWithCodeCard v-else-if="step === steps.resetPasswordWithCode" :resetPasswordStatus="this.resetPasswordStatus" :sendResetCodeStatus="this.sendResetCodeStatus" :email="this.email" @cancel-reset="cancelForgetPasswordReset" @show-toast-message="showToastMessage" @set-new-password="setNewPassword" @resend-code="requestResetPasswordCode"/>
          </template>
        </Card>
      </div>
    </UnauthenticatedLayout>
  </main>
</template>

<script>
import UnauthenticatedLayout from "@/layouts/UnauthenticatedLayout.vue";
import LoginCard from "@/components/LoginCard.vue";
import MfaCard from "@/components/MfaCard.vue";
import SetPasswordCard from "@/components/SetPasswordCard.vue";
import AuthenticationService from "@/services/AuthenticationService";
import { useToast } from 'primevue/usetoast';
import ForgetPasswordCard from "@/components/ForgetPasswordCard.vue";
import ResetPasswordWithCodeCard from "@/components/ResetPasswordWithCodeCard.vue";



const steps = {
  login: 'login',
  mfaVerification: 'mfa-verification',
  setPassword: 'set-password',
  forgetPassword: 'forget-password',
  resetPasswordWithCode: 'reset-password-with-code'
};

export default {
  components: {ResetPasswordWithCodeCard, SetPasswordCard, MfaCard, LoginCard, UnauthenticatedLayout, ForgetPasswordCard},
  data() {
    return {
      steps,
      step: steps.login,
      email: '',
      password: '',
      loginStatus: '',
      mfaStatus: '',
      setPasswordStatus: '',
      sendResetCodeStatus: '',
      resetPasswordStatus: '',
    };
  },
  setup() {
    const toast = useToast();

    const showToastMessage = (severity, summary, detail) => {
      toast.add({
        severity: severity,
        summary: summary,
        detail: detail,
        group: 'tr',
        life: 3000
      });
    };

    return { showToastMessage };
  },
  methods: {
    async login(email, password) {
      this.email = email;
      this.password = password;
      this.loginStatus = '';
      const loginResponse = await AuthenticationService.login(this.$store, email, password);
      this.loginStatus = loginResponse.status;
      if (this.loginStatus === "new_password_required") {
        console.log('new_password_required');
        this.step = steps.setPassword;
      } else if (this.loginStatus === "error") {
        this.showToastMessage(
            'error',
            'Login Error',
            loginResponse.message
        );
      } else if (this.loginStatus === "success") {
        this.step = steps.mfaVerification;
        this.showToastMessage(
            'success',
            'Login Successful',
            'Please enter the verification code sent to your email.'
        );
      }
    },
    forgetPassword() {
      this.step = steps.forgetPassword;
    },
    cancelForgetPassword() {
      this.step = steps.login;
    },
    async verifyMfaCode(email, mfaCode) {
      this.mfaStatus = '';
      const mfaResponse = await AuthenticationService.verifyMFACode(this.$store, email, mfaCode, this.$store.state.session);
      this.mfaStatus = mfaResponse.status;
      if (this.mfaStatus === "success") {
        this.$router.push('/dashboard');
      } else {
        this.showToastMessage(
            'error',
            'MFA Error',
            'Failed to verify MFA code. Please check the code and try again.'
        );
      }
    },
    async resendCode() {
      this.mfaStatus = 'pending';
      await AuthenticationService.login(this.$store, this.email, this.password);
      this.showToastMessage(
          'info',
          'Verification Code Resent',
          'A new verification code has been sent to your email.'
      );
      this.mfaStatus = '';
    },
    cancelMfa() {
      this.step = steps.login;
    },
    cancelSetPassword() {
      this.step = steps.login;
    },
    cancelForgetPasswordReset() {
      this.step = steps.forgetPassword;
    },
    async requestResetPasswordCode(email) {
      this.sendResetCodeStatus = 'pending';
      const requestResetPasswordCodeResponse = await AuthenticationService.requestResetPasswordCode(email);
      this.sendResetCodeStatus = requestResetPasswordCodeResponse.status;
      if (requestResetPasswordCodeResponse.status === "success") {
        this.step = steps.resetPasswordWithCode;
        this.email = email;
        this.showToastMessage(
            'success',
            'Reset Password Code Sent',
            'A reset password code has been sent to your email.'
        );
      } else {
        this.showToastMessage(
            'error',
            'Reset Password Error',
            requestResetPasswordCodeResponse.message
        );
      }

      this.sendResetCodeStatus = '';
    },
    async setNewPassword(code, newPassword)
    {
      this.resetPasswordStatus = '';
      const resetPasswordResponse = await AuthenticationService.forgetPasswordConfirm(this.email, code, newPassword);
      this.resetPasswordStatus = resetPasswordResponse.status;
      if (this.resetPasswordStatus === "success") {
        this.step = steps.login;
        this.showToastMessage(
            'success',
            'Reset Password Success',
            'Password reset successfully. Please login with your new password.'
        );
      } else {
        this.showToastMessage(
            'error',
            'Reset Password Error',
            resetPasswordResponse.message
        );
      }
    },
    async loginWithTempPassword(newPassword) {
      this.setPasswordStatus = '';
      const loginWithTempPasswordResponse = await AuthenticationService.loginWithTempPassword(this.$store, this.email, this.password, newPassword, newPassword);
      this.setPasswordStatus = loginWithTempPasswordResponse.status;
      if (this.setPasswordStatus === "success") {
        this.$router.push('/dashboard');
      } else {
        this.showToastMessage(
            'error',
            'Set Password Error',
            'Failed to set new password. Please try again.'
        );
      }
    },
  },
};
</script>

<style scoped>

</style>