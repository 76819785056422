<script setup>

import {onMounted, provide, ref, watch} from "vue";
import {useToast} from 'primevue/usetoast';
import {useRoute} from 'vue-router';
import tabService from "@/services/TabService";
import tokenService from "@/services/TokenService";
import AuthenticationService from "@/services/AuthenticationService";
import {useStore} from "vuex";
import { useRouter } from 'vue-router';

const router = useRouter(); // Access the router instance

const route = useRoute();
const store = useStore();

const tabs = [
  {"label": "Dashboard", "icon": "pi pi-fw pi-home", "route": "/dashboard"},
  {"label": "Schools", "icon": "pi pi-fw pi-building", "route": "/schools"},
  {"label": "Users", "icon": "pi pi-fw pi-users", "route": "/users"},
  {"label": "Upload", "icon": "pi pi-fw pi-upload", "route": "/upload"},
  {"label": "Eligibility Check", "icon": "pi pi-fw pi-verified", "route": "/eligibility-check"},
  {"label": "Documentation", "icon": "pi pi-fw pi-file", "route": "/docs"},
];

const home = ref({
  "icon": 'pi pi-home',
  "route": '/'
});

const items = ref([
  {"label": 'Dashboard', "route": '/dashboard'},
]);

watch(route, (newRoute) => {
  const matchedTab = tabs.find(tab => tab.route === newRoute.path);
  if (matchedTab) {
    items.value = [
      {"label": matchedTab.label, "route": matchedTab.route}
    ];
  } else {
    items.value = [{"label": 'Dashboard', "route": '/dashboard'}];
  }
});


function showToastMessage(severity, summary, detail) {
  console.log("showToastMessage", severity, summary, detail);
  toast.add({
    "severity": severity,
    "summary": summary,
    "detail": detail,
    "group": 'tr',
    "life": 3000
  });
}

provide("showToastMessage", showToastMessage);

const toast = useToast();

const menuVisible = ref(true);

const toggleMenu = () => {
  menuVisible.value = !menuVisible.value;
};

const isProfileDropdownVisible = ref(false);
const toggleProfileDropdownMenu = () => {
  isProfileDropdownVisible.value = !isProfileDropdownVisible.value;
};



const showDashboardTab = ref(true);
const showSchoolsTab = ref(false);
const showUsersTab = ref(false);
const showUploadTab = ref(false);
const showApiTab = ref(false);
const showDocsTab = ref(true);


function getApiConfig() {
  const token = store.state.userToken;
  if (tokenService.isTokenExpired(token)) {
    AuthenticationService.logout(store, route);
    return;
  }

  return {
    "headers": {
      "Authorization": `Bearer ${token}`,
    },
  };
}

async function checkUserPermissionsForTabs() {
  const config = getApiConfig();
  const tabs = await tabService.listUserTabPermissions(config, store, router);
  console.log("tabs", tabs);

  if(!tabs) {
    return;
  }

  showSchoolsTab.value = tabs.includes('schools');
  showUsersTab.value = tabs.includes('users');
  showUploadTab.value = tabs.includes('upload');
  showApiTab.value = tabs.includes('api');
  showDocsTab.value = tabs.includes('docs');
}

async function redirectUserToFirstAvailableTab() {
  const path = await tabService.getFirstAvailableTabUrl();
  console.log("path", path);
  home.value.route = path;

  const current = route.path;

  if (current === path) {
    return false;
  }

  navigateTo(path)
}

function navigateTo(path) {
  router.push({ path });
}


onMounted(async () => {
  console.log("DashboardLayout mounted");
  await checkUserPermissionsForTabs();
  redirectUserToFirstAvailableTab();
});

const userName = ref(store.getters.userName);

async function logout() {
  const token = store.state.userToken;
  if (tokenService.isTokenExpired(token)) {
    return AuthenticationService.logout(store, router, true);
  }

  await AuthenticationService.callLogoutApi(store, router);
  isProfileDropdownVisible.value = false;
}
</script>

<template>
  <div class="flex h-[100vh]">
    <Toast position="top-right" group="tr"/>
    <div v-if="menuVisible" id="left-menu" class="card flex flex-col w-[250px] bg-blue-950">
      <router-link to="/" class="px-8 py-4">
        <img id="logo"  alt="user header" src="/images/logo_white.svg"/>
      </router-link>

      <Menu :model="tabs"
            class="
            w-full
            mt-4
            bg-transparent
            text-white
            !border-none">
      <template #item="{ item : tab, props }">
          <router-link v-if="tab.route" v-slot="{ href, navigate }" :to="tab.route" custom>
            <a v-ripple :href="href" v-bind="props.action" @click="navigate" class="py-5 px-7">
              <span :class="tab.icon"/>
              <span class="ml-2">{{ tab.label }}</span>
            </a>
          </router-link>
          <a v-else v-ripple :href="tab.url" :target="tab.target" v-bind="props.action">
            <span :class="tab.icon"/>
            <span class="ml-2">{{ tab.label }}</span>
          </a>
        </template>
      </Menu>
    </div>
    <div id="right-area" class="flex-grow bg-gray-100 px-10">
      <div id="top-bar" class="px-2 py-1  flex justify-between items-center text-gray-500">
        <div class="flex">
          <Button icon="pi pi-bars" @click="toggleMenu" class="text-black bg-transparent border-none"/>
          <Breadcrumb :home="home" :model="items" class="text-black bg-transparent border-none font-medium">
            <template #item="{ item, props }">
              <router-link v-if="item.route" v-slot="{ href, navigate }" :to="item.route" custom>
                <a :href="href" v-bind="props.action" @click="navigate">
                  <span :class="[item.icon, 'text-color']"/>
                  <span class="text-primary font-semibold">{{ item.label }}</span>
                </a>
              </router-link>
              <a v-else :href="item.url" :target="item.target" v-bind="props.action">
                <span class="text-surface-700 dark:text-surface-0">{{ item.label }}</span>
              </a>
            </template>
          </Breadcrumb>
        </div>

        <div class="relative">
          <div class="flex items-center cursor-pointer gap-4" @click="toggleProfileDropdownMenu">
            <Avatar icon="pi pi-user" class="bg-green-500 text-white" shape="circle" />
            <span class="font-semibold text-blue-900">{{ userName }}</span>
            <i class="pi pi-chevron-down"></i>
          </div>

          <div v-if="isProfileDropdownVisible" class="absolute top-full mt-2 bg-white shadow-lg rounded-lg p-4 w-full">
            <ul>
              <li class="py-2 px-4 hover:bg-gray-100 cursor-pointer">Profile</li>
              <li class="py-2 px-4 hover:bg-gray-100 cursor-pointer" @click="logout">Logout</li>
            </ul>
          </div>
        </div>
      </div>
      <div class="">
        <router-view :showDashboardTab="showDashboardTab"
                     :showSchoolsTab="showSchoolsTab"
                     :showUsersTab="showUsersTab"
                     :showUploadTab="showUploadTab"
                     :showApiTab="showApiTab"
                     :showDocsTab="showDocsTab"/>
      </div>

    </div>
  </div>
</template>

<style scoped>
</style>