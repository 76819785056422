import axios from "axios";

const AuthenticationService = {
    clearLoginData: (store) => {
        store.commit('removeUserName');
        store.commit('removeUserToken');
        store.commit('removeRefreshToken');
        store.commit('removeSession');
    },
    callLogoutApi: async (store, router) => {
        const token = store.state.userToken;
        const config = {
            headers: {
                Authorization: `Bearer ${token}`,
            },
        };

        await axios.post(process.env.VUE_APP_API_BASE_URL + '/api/cognito/logout', {}, config)
            .then(() => {
                AuthenticationService.clearLoginData(store);
                router.push('/login');
            })
            .catch(error => {
                console.error('There was an error!', error);
                AuthenticationService.clearLoginData(store);
                router.push('/login');
            });
    },
    logout: (store, router, hideMessage) => {
        if (!hideMessage) {
            AuthenticationService.clearLoginData(store);
            store.commit('setShowTokenExpiringMessage', 'Your session expired. Please login to use the system.');
            router.push('/login');
        }
    },
    disable: (store, router) => {
        store.commit('setUserToken', 'disabled');
        store.commit('setUserName', '');
        store.commit('setShowTokenExpiringMessage', '');
        router.push('/login');
    },
    login: async (store, email, password) => {
        console.log('Login from authentication service:', email, password);
        try {
            const response = await axios.post(process.env.VUE_APP_API_BASE_URL + `/api/cognito/login`, {
                email: email,
                password: password,
                authChallengeType: "email"
            });

            if (response.data.ChallengeName && response.data.ChallengeName == "NEW_PASSWORD_REQUIRED") {
                console.error('ChallengeName detected:', response.data.ChallengeName);
                return {status: 'new_password_required'};
            }

            console.log('Login success:', response);
            store.commit('setSession', response.data.Session);
            //set user name
            await AuthenticationService.getUserName(store);
            return {status: 'success'};
        } catch (error) {
            console.error('Failed to login:', error.response || error);
            return {
                status: 'error',
                message: error.response.data.message
            };
        }
    },
    verifyMFACode: async (store, email, mfaCode, session) => {
        return await axios.post(process.env.VUE_APP_API_BASE_URL + `/api/cognito/login/verify`, {
            email: email,
            code: mfaCode,
            session: session,
        }).then(async (response) => {
            // Save the user token
            store.commit('setUserToken', response.data.access_token);
            store.commit('setRefreshToken', response.data.refresh_token);

            return {status: 'success'};
        }).catch((error) => {
            console.error('Failed to verify MFA:', error.response || error);
            const errorMessage = error.response?.data?.message;
            console.log('Error message:', errorMessage);
            //reset the session
            store.commit('removeSession');
            const newSession = error.response?.data?.Session ?? '';
            store.commit('setSession', newSession);
            return {status: 'error'};
        });
    },
    loginWithTempPassword: async function (store, email, tempPassword, newPassword, confirmPassword) {
        return await axios.post(process.env.VUE_APP_API_BASE_URL + `/api/cognito/login-by-temporary-password`, {
            email: email,
            temporary_password: tempPassword,
            new_password: newPassword,
            new_password_confirmation: confirmPassword,
        }).then(async (response) => {
            this.errorMessage = '';
            this.infoMessage = 'Registration verified';
            this.userToken = response.data.access_token;
            this.refreshToken = response.data.refresh_token;
            store.commit('setUserToken', this.userToken);
            store.commit('setRefreshToken', this.refreshToken);
            return {status: 'success'};
        }).catch((error) => {
            console.error('Failed to login with temporary password:', error.response || error);
            return {status: 'error'};
        });
    },
    requestResetPasswordCode: async function (email) {
        return await axios.post(process.env.VUE_APP_API_BASE_URL + `/api/cognito/forgot-password`, {
            email: email,
        }).then(() => {
            return {status: 'success'};
        }).catch((error) => {
            console.error('Failed to request reset password code:', error.response || error);
            return {status: 'error', message: error.response.data.message};
        });
    },
    forgetPasswordConfirm: async function (email, code, newPassword) {
        return await axios.post(process.env.VUE_APP_API_BASE_URL + `/api/cognito/forgot-password/confirm`, {
            email: email,
            code: code,
            password: newPassword,
            password_confirmation: newPassword,
        }).then(() => {
            return {status: 'success'};
        }).catch((error) => {
            console.error('Failed to confirm forgot password:', error.response || error);
            return {status: 'error', message: error.response.data.message};
        });
    },
    getUserName: async (store) => {
        const token = store.state.userToken;
        const config = {
            headers: {
                Authorization: `Bearer ${token}`,
            },
        };
        await axios.get(process.env.VUE_APP_API_BASE_URL + '/api/accounts/username', config)
            .then(response => {
                this.userName = response.data.username;
                console.log('User name:', this.userName);
                store.commit('setUserName', this.userName);
            })
            .catch(error => {
                console.error('There was an error!', error);
            });
    },
}

export default AuthenticationService;
