<template>
  <div class="card flex flex-wrap justify-center px-6 gap-6 mb-8">
    <h3 class="text-2xl font-medium">Set Your Password</h3>

    <div class="icon-field-row w-full">
      <IconField>
        <InputIcon class="pi pi-lock z-10"/>
        <Password class="w-full [&_input]:pl-10 [&_input]:w-full" v-model="newPassword" placeholder="New password"
                  :feedback="true" @input="validatePassword" strong-regex="^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[@$!%*#?&])(?=.{8,})" toggleMask>
          <template #header>
            <div class="font-semibold text-xm mb-4">Pick a password</div>
          </template>
          <template #footer>
            <Divider />
            <ul class="pl-2 ml-2 my-0 leading-normal">
              <li :class="{ 'text-green-500': hasLowerCase, 'text-red-500': !hasLowerCase }">At least one lowercase</li>
              <li :class="{ 'text-green-500': hasUpperCase, 'text-red-500': !hasUpperCase }">At least one uppercase</li>
              <li :class="{ 'text-green-500': hasNumeric, 'text-red-500': !hasNumeric }">At least one numeric</li>
              <li :class="{ 'text-green-500': hasSpecialChar, 'text-red-500': !hasSpecialChar }">At least one special character</li>
              <li :class="{ 'text-green-500': isLongEnough, 'text-red-500': !isLongEnough }">Minimum 8 characters</li>
            </ul>
          </template>
        </Password>
      </IconField>
    </div>

    <div class="icon-field-row w-full">
      <IconField>
        <InputIcon class="pi pi-lock z-10"/>
        <Password class="w-full [&_input]:pl-10 [&_input]:w-full" v-model="confirmPassword" placeholder="Confirm password"
                  :feedback="false" toggleMask>
        </Password>
      </IconField>
    </div>
  </div>
  <div class="grid gap-6 px-6 grid-cols-2 mt-8">
    <Button label="Cancel" @click="cancelSetPassword" outlined/>
    <Button label="Continue" @click="saveAndContinue" :disabled="!isPasswordValid || (loading&&keepLoading)" :loading="loading&keepLoading"/>
  </div>
</template>

<script>
export default {
  name: 'SetPasswordCard',
  props: {
    setPasswordStatus: {
      type: String,
      required: true,
    },
  },
  data() {
    return {
      newPassword: '',
      confirmPassword: '',
      hasLowerCase: false,
      hasUpperCase: false,
      hasNumeric: false,
      hasSpecialChar: false,
      isLongEnough: false,
      loading: false,
    };
  },
  computed: {
    isPasswordValid() {
      return this.hasLowerCase && this.hasUpperCase && this.hasNumeric && this.hasSpecialChar && this.isLongEnough;
    },
    keepLoading() {
      console.log('set password status: ' + this.setPasswordStatus);
      return this.setPasswordStatus !== 'error';
    }
  },
  methods: {
    validatePassword() {
      const password = this.newPassword;

      this.isLongEnough = password.length >= 8;
      this.hasUpperCase = /[A-Z]/.test(password);
      this.hasLowerCase = /[a-z]/.test(password);
      this.hasSpecialChar = /[@$!%*#?&]/.test(password);
      this.hasNumeric = /\d/.test(password);
    },
    cancelSetPassword() {
      this.$emit('cancel-set-password');
    },
    saveAndContinue() {
      this.loading = true;
      if (this.newPassword !== this.confirmPassword || !this.newPassword || !this.confirmPassword) {
        this.$emit('show-toast-message', 'error', 'Error', 'Passwords do not match');
        this.loading = false;
        return;
      }

      this.$emit('login-with-temp-password', this.newPassword);
    }
  }
};
</script>

<style scoped>
</style>